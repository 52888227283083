import React, { useEffect, useState } from "react";
import Back_button from "../Back-to-top/back_button";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import Subbanner from "../Subbanner/Subbanner";
import { ToastContainer, toast } from "react-toastify";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";
import "./Contact.css";
import Talk from "../Talk/Talk";

const Contact = () => {
  const [loader, setLoader] = useState(true);
  const [contactdata, setContactdata] = useState({
    name: "",
    email: "",
    phone_number: "",
    msg_subject: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    getloading();
  }, []);

  const getloading = () => {
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  const handleChange = (e) => {
    setContactdata({ ...contactdata, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, [formErrors]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setFormErrors(validate(contactdata));
    if (
      contactdata.name !== "" &&
      contactdata.email !== "" &&
      contactdata.phone_number !== "" &&
      contactdata.msg_subject !== "" &&
      contactdata.message !== ""
    ) {
    } else {
      if (contactdata.name === "") {
        toast.error("please fill name !!");
      }
      if (contactdata.email === "") {
        toast.error("please fill email !!");
      }
      if (contactdata.phone_number === "") {
        toast.error("please fill phone number !!");
      }
      if (contactdata.msg_subject === "") {
        toast.error("please fill subject !!");
      }
      if (contactdata.message === "") {
        toast.error("please fill message !!");
      }
    }
    await addDoc(collection(db, "Contact"), {
      timestamp: serverTimestamp(),
      title: contactdata.name,
      Sub_title: contactdata.email,
      phone_number: contactdata.phone_number,
      msg_subject: contactdata.msg_subject,
      message: contactdata.message,
    })
      .then(() => {
        toast("Your message send successfully", { type: "success" });
      })
      .catch((error) => {
        toast.error("Error adding Form");
      });
    setContactdata("");
  };
  const validate = (contactdata) => {
    const errors = {};
    if (!contactdata.name) {
      errors.name = "Name is required!";
    } else if (!contactdata.email) {
      errors.email = "Email is required!!";
    } else if (!contactdata.phone_number) {
      errors.phone_number = "Phone number is required!!";
    } else if (!contactdata.msg_subject) {
      errors.msg_subject = "Subject is required!!";
    } else if (!contactdata.message) {
      errors.message = "Message is required!!";
    }
    return errors;
  };
  return (
    <>
      <Navbar />
      <ToastContainer />

      {loader ? (
        <div id="preloader"></div>
      ) : (
        <>
          <Subbanner pagename={"Contact Us"} />
          {/* <div className="contact-area pt-100 pb-70">
            <div className="container contact-width">
              <div className="row">
                <div className="col-lg-4 col-sm-6">
                  <div className="contact-card">
                    <i className="bx bxs-phone"></i>
                    <ul>
                      <li>
                        <a href="tel:+919726981087 ">+91 97269 81087 </a>
                      </li>
                      <li>
                        <a href="tel:+19876543210">+1 9876 543 210</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <div className="contact-card">
                    <i className="bx bxl-telegram"></i>
                    <ul>
                      <li>
                        <a href="mailto:admin@softholic.in">
                          admin@softholic.in
                        </a>
                      </li>
                      <li>
                        <a href="mailto:admin@softholic.in">
                          admin@softholic.in
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                  <div className="contact-card">
                    <i className="bx bx-current-location"></i>
                    <ul>
                      <li style={{ lineHeight: 1.5 }}>
                        6th floor, Laxmi Enclave 2
                        <br />
                        Katargam, Surat, Gujarat - 395004
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <Talk/>
          {/* <div className="map-area">
            <div className=" p-0" data-aos="zoom-in">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7437.975593424885!2d72.8365365!3d21.2323325!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x272710f4d928f651!2sLaxmi%20Enclave%202!5e0!3m2!1sen!2sin!4v1665203787670!5m2!1sen!2sin"
                title="map"
              ></iframe>
            </div>
          </div> */}
          <section className="contact-section pb-100">
            <div className="container">
              <div className="section-title text-center contact-detail">
                {/* <span>Contact Us</span> */}
                <h2>Drop A Message For Any Query</h2>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                </p>
              </div>
              <div className="contact-wrap pt-45">
                <div className="contact-wrap-form">
                  <form id="contactForm" onSubmit={onSubmit} autoComplete="off">
                    <div className="row">
                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="name"
                            value={contactdata.name}
                            className="form-control1"
                            placeholder="Your Name"
                            onChange={(e) => handleChange(e)}
                          />
                          <p className="errorcolor"> {formErrors.name} </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            value={contactdata.email}
                            className="form-control1"
                            placeholder="Your Email"
                            onChange={(e) => handleChange(e)}
                          />
                          <p className="errorcolor"> {formErrors.email} </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="phone_number"
                            value={contactdata.phone_number}
                            className="form-control1"
                            placeholder="Your Phone"
                            onChange={(e) => handleChange(e)}
                          />
                          <p className="errorcolor">
                            {formErrors.phone_number}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="msg_subject"
                            value={contactdata.msg_subject}
                            className="form-control1"
                            placeholder="Your Subject"
                            onChange={(e) => handleChange(e)}
                          />
                          <p className="errorcolor">{formErrors.msg_subject}</p>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <textarea
                            name="message"
                            value={contactdata.message}
                            className="form-control1"
                            cols="30"
                            rows="8"
                            placeholder="Your Message"
                            onChange={(e) => handleChange(e)}
                          ></textarea>
                          <p className="errorcolor"> {formErrors.message} </p>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 text-center">
                        <button
                          type="submit"
                          className="default-btn page-btn text-center disabled"
                          style={{ pointerevents: "all", cursor: "pointer" }}
                        >
                          Send Message
                        </button>
                        <div
                          id="msgSubmit"
                          className="h3 text-center hidden"
                        ></div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
          <Footer />
          <Back_button />
        </>
      )}
    </>
  );
};

export default Contact;
