import React from "react";
import "./Questions.css";

const Questions = () => {
  return (
    <section id="faq" className="faq section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h3>Frequently Asked Questions</h3>
          <p>
            We hope that it will help you better understand the issues related
            to software development.
          </p>
        </div>

        <div className="accordion-list">
          <ul>
            <li data-aos="fade-up">
              {/* <i className="bx bx-help-circle icon-help"></i> */}
              <span className=" icon-help">01</span>

              <a
                data-bs-toggle="collapse"
                className="collapsed"
                data-bs-target="#accordion-list-1"
                href="#Questions"
              >
                What can Softholic help me with?
                <i className="bx bx-chevron-down icon-show"></i>
                <i className="bx bx-chevron-up icon-close"></i>
              </a>
              <div
                id="accordion-list-1"
                className="collapse "
                data-bs-parent=".accordion-list"
              >
                <p>
                  With whatever we can. Preparing cost analysis, establishing
                  team roles, creating roadmaps. Defining business goals, key
                  business values, target users, main features, prioritising. We
                  can find you similar projects that are already active on the
                  market to benchmark against.
                </p>
                <p>
                  We can assist you with creating user personas, mock-ups, user
                  stories, time frame, establishing project status and preparing
                  project estimation. We'll be happy to outline project
                  specification, prepare wireframes, details concerning
                  integrations with external services, API documentation,
                  required features list.
                </p>
                <p>
                  In terms of project development we prepare server-side
                  architecture, front end, back end, graphic and UX design, and
                  create back-office consoles.
                </p>
                <p>
                  We are also happy to advise our customers in terms of
                  budgeting, scheduling, risk management, and business model
                  creation.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="100">
              {/* <i className="bx bx-help-circle icon-help"></i> */}
              <span className=" icon-help">02</span>
              <a
                data-bs-toggle="collapse"
                data-bs-target="#accordion-list-2"
                className="collapsed"
                href="#Questions"
              >
                Why should I choose Softholic and not hire my own software
                development team?
                <i className="bx bx-chevron-down icon-show"></i>
                <i className="bx bx-chevron-up icon-close"></i>
              </a>
              <div
                id="accordion-list-2"
                className="collapse"
                data-bs-parent=".accordion-list"
              >
                <p>
                  Hiring your own software developers can be a really smart
                  choice, however, recruitment process is time-consuming and the
                  costs of keeping an in-house programming team are very high.
                </p>
                <p>
                  You'll probably need to employ your own software engineers
                  sooner or later but hiring us can help you save a lot of money
                  and time.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="100">
              <span className=" icon-help">03</span>

              <a
                data-bs-toggle="collapse"
                data-bs-target="#accordion-list-4"
                className="collapsed"
                href="#Questions"
              >
                How do you guarantee product quality?{" "}
                <i className="bx bx-chevron-down icon-show"></i>
                <i className="bx bx-chevron-up icon-close"></i>
              </a>
              <div
                id="accordion-list-4"
                className="collapse"
                data-bs-parent=".accordion-list"
              >
                <p>
                  We evaluate the result after every two weeks, we test our work
                  , we present it to you, we apply your feedback so you know you
                  get what you are paying for.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="100">
              <span className=" icon-help">04</span>

              <a
                data-bs-toggle="collapse"
                data-bs-target="#accordion-list-5"
                className="collapsed"
                href="#Questions"
              >
                Can the software be upgraded in the future as new technology
                becomes available?
                <i className="bx bx-chevron-down icon-show"></i>
                <i className="bx bx-chevron-up icon-close"></i>
              </a>
              <div
                id="accordion-list-5"
                className="collapse"
                data-bs-parent=".accordion-list"
              >
                <p>
                  Of course! We are always happy to improve, upgrade and further
                  develop our work.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Questions;
