import React from 'react'
import "./Specialties.css";
import AWS from "../img/Specialties/AWS.svg";
import GoogleCloud from "../img/Specialties/GoogleCloud.svg";
import Gradle from "../img/Specialties/Gradle.svg";
import Jenkins from "../img/Specialties/Jenkins.svg";
import Appium from "../img/Specialties/Appium.svg";
import Selenium from "../img/Specialties/Selenium.svg";

const Infra = () => {
  return (
    <div className="mt-4" data-aos="fade-up">
      <div className="justify-content-center row" style={{ margin: 0 }}>
        <div className="mt-3 resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={AWS}
                alt="Photoshop"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              AWS
            </h4>
          </div>
        </div>
        <div className="mt-3 resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={GoogleCloud}
                alt="Figma"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Google Cloud
            </h4>
          </div>
        </div>
        <div className="mt-3 resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={Gradle}
                alt="Illustrator"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Gradle
            </h4>
          </div>
        </div>
        <div className="mt-3 resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={Jenkins}
                alt="Maya"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Jenkins
            </h4>
          </div>
        </div>
        <div className="mt-3 resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={Appium}
                alt="Max"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Appium
            </h4>
          </div>
        </div>
        <div className="mt-3 resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={Selenium}
                alt="Blender"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Selenium
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Infra
