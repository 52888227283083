import React from "react";
import "./Specialties.css";
import mongodb from "../img/Specialties/mongodb.svg";
import Mysql from "../img/Specialties/Mysql.svg";
import firebase from "../img/Specialties/firebase.svg";
import Oracle from "../img/Specialties/Oracle.svg";
import PostgreSQL from "../img/Specialties/PostgreSQL.svg";

const Database = () => {
  return (
    <div className="mt-4" data-aos="fade-up">
      <div className="justify-content-center row" style={{ margin: 0 }}>
        <div className="mt-3 resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={mongodb}
                alt="Photoshop"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Mongo DB
            </h4>
          </div>
        </div>
        <div className="mt-3 resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={Mysql}
                alt="Figma"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              MySQL
            </h4>
          </div>
        </div>
        <div className="mt-3 resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={firebase}
                alt="Illustrator"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Firebase
            </h4>
          </div>
        </div>
        <div className="mt-3 resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={Oracle}
                alt="Maya"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Oracle
            </h4>
          </div>
        </div>
        <div className="mt-3 resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={PostgreSQL}
                alt="Max"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              PostgreSQL
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Database;
