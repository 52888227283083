import React, { useEffect, useState } from "react";
import "./Services.css";
import ios from "../img/Specialties/apple.svg";
import android from "../img/Specialties/android.png";
import react from "../img/Specialties/react.svg";
import flutter from "../img/Specialties/flutter.svg";
import Figma from "../img/Specialties/Figma.svg";
import Photoshop from "../img/Specialties/Photoshop.svg";
import Illustrator from "../img/Specialties/Illustrator.svg";
import Blender from "../img/Specialties/Blender.svg";
import Footer from "../Footer/Footer";
import Back_button from "../Back-to-top/back_button";
import Specialties from "../Specialties/Specialties";
import Navbar from "../Navbar/Navbar";
import Subbanner from "../Subbanner/Subbanner";
import CrossPlatform  from "../img/microsoft-technology.svg";
import Ui_Ux from "../img/4806449.png";
import Marketing from "../img/Marketing.jpg";
import p1 from "../img/mobile-app-development.svg";



const Services = () => {
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    getloading();
  }, []);

  const getloading = () => {
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  return (
    <>
      <Navbar />
      {loader ? (
        <div id="preloader"></div>
      ) : (
        <>
          <Subbanner pagename={"Services"} />

          <div className="container-fluid" style={{ marginTop: "20px" }}>
            <section className="section1">
              <div className="row">
                <div
                  className="col-lg-5 col-md-12 col-sm-12"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div className="web-pro-left-text">
                    <img
                      src={p1}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-7 col-md-12 col-sm-12 elementor-widget-wrap">
                  <div className="sub-title">
                    <h1>Front-end Technologies</h1>
                  </div>
                  <div className="content">
                    <p>
                      At Softholic, our custom front-end solutions facilitate
                      authority & agility to digitalize your business. Using the
                      best-suited frameworks for your requirements, we come up
                      with high-performing, clutter-free solutions. Powered by
                      our experience with JS frameworks like Angular and React,
                      and the latest libraries, we create dynamic and 100%
                      responsive solutions that are intuitive, neat, easy to
                      use, and oriented towards the end user. Our front-end
                      development services are end-to-end.
                    </p>
                  </div>
                  <div className="icon-list">
                    <ul>
                      <li>
                        <img src={ios} alt="" />
                      </li>
                      <li>
                        <img src={android} alt="" />
                      </li>
                      <li>
                        <img src={react} alt="" />
                      </li>
                      <li>
                        <img src={flutter} alt="" />
                      </li>
                    </ul>
                  </div>
                  <div className="list-item row">
                    <div className="col-lg-5 col-md-6 col-sm-12">
                      <ul className="list-item1">
                        <li>
                          <i className="bx bx-chevrons-right"></i>Mobile app
                          UI-UX design
                        </li>
                        <li>
                          <i className="bx bx-chevrons-right"></i>UX
                          optimization
                        </li>
                        <li>
                          <i className="bx bx-chevrons-right"></i>PSD to HTML &
                          CSS
                        </li>
                        <li>
                          <i className="bx bx-chevrons-right"></i>CSS web
                          designing
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-12">
                      <ul className="list-item2">
                        <li>
                          <i className="bx bx-chevrons-right"></i>AngularJS,
                          NodeJS, ReactJS, VueJS
                        </li>
                        <li>
                          <i className="bx bx-chevrons-right"></i>JavaScript web
                          application development
                        </li>
                        <li>
                          <i className="bx bx-chevrons-right"></i>Re-design
                          existing websites & apps
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="section1">
              <div className="row flex_direction">
                <div className="col-lg-7 col-md-12 col-sm-12 elementor-widget-wrap">
                  <div className="sub-title">
                    <h1>Cross Platform Apps</h1>
                  </div>
                  <div className="content">
                    <p>
                      Softholic is your preferred choice for full-service
                      cross-platform app development. Our apps yield a premium
                      performance in the terms of low market launch times, easy
                      updates, compatible technology, and cost-effectiveness.
                      For high engagement and conversion levels, our apps
                      deliver the desired outcomes throughout devices,
                      platforms, audiences, and industries. They span your reach
                      to your default customers in maximum numbers. Our team is
                      well-versed in the use of top frameworks used for
                      cross-platform app development, including:
                    </p>
                  </div>
                  <div className="icon-list">
                    <ul>
                      <li className="li">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 48 48"
                          width="48px"
                          height="48px"
                        >
                          <path
                            fill="#eceff1"
                            d="M16,42h16c5.523,0,10-4.477,10-10V16c0-5.523-4.477-10-10-10H16C10.477,6,6,10.477,6,16v16C6,37.523,10.477,42,16,42z"
                          ></path>
                          <path
                            fill="#ffc107"
                            d="M12.783 17.974A0.762 0.762 0 1 0 12.783 19.498A0.762 0.762 0 1 0 12.783 17.974Z"
                          ></path>
                          <path
                            fill="#ff5722"
                            d="M15.982 21.81l1.444-.61c.68-1.22 1.835-1.927 3.332-1.927.34 0 .659.043.962.113l1.372-.579c-.676-.333-1.451-.526-2.334-.526C18.368 18.281 16.663 19.594 15.982 21.81zM13.297 22.944L13.297 21.375 12.273 21.375 12.273 23.377z"
                          ></path>
                          <path
                            fill="#f44336"
                            d="M13.297 25.733L13.297 22.944 12.273 23.377 12.273 26.165zM16.742 24.148c0-1.169.246-2.163.684-2.948l-1.444.61c-.214.696-.333 1.476-.333 2.338 0 .201.028.382.04.574l1.062-.449C16.75 24.23 16.742 24.192 16.742 24.148zM30.421 18.5c-.279.086-.537.195-.774.327L30.421 18.5zM23.092 18.807l-1.372.579c1.027.237 1.828.863 2.35 1.796l1.022-.432C24.624 19.878 23.941 19.226 23.092 18.807z"
                          ></path>
                          <path
                            fill="#e91e63"
                            d="M13.297 28.521L13.297 25.733 12.273 26.165 12.273 28.953zM30.421 18.5l-.774.327c-.983.547-1.577 1.464-1.577 2.58 0 .302.046.571.117.825l1.032-.436c-.034-.132-.056-.27-.056-.42 0-1.227 1.117-2.117 2.734-2.117.796 0 1.467.213 1.958.579l1.048-.443c-.694-.684-1.735-1.113-2.974-1.113C31.381 18.281 30.876 18.36 30.421 18.5zM16.75 24.274l-1.062.449c.059.959.26 1.811.597 2.536l1.004-.424C16.954 26.121 16.766 25.26 16.75 24.274zM25.092 20.751l-1.022.432c.381.682.603 1.532.658 2.51l1.061-.448C25.695 22.297 25.467 21.452 25.092 20.751z"
                          ></path>
                          <g>
                            <path
                              fill="#9c27b0"
                              d="M25.609 26.108c.146-.602.242-1.247.242-1.96 0-.316-.033-.609-.063-.904l-1.061.448c.009.153.03.296.03.456 0 .968-.177 1.809-.481 2.523L25.609 26.108zM17.29 26.834l-1.004.424c.408.879 1.008 1.568 1.777 2.038l1.258-.531C18.42 28.427 17.727 27.764 17.29 26.834zM13.297 28.521L12.273 28.953 12.273 29.789 13.297 29.789zM29.22 21.795l-1.032.436c.245.866.915 1.471 2.129 1.889l1.6-.676-.338-.085C30.122 22.995 29.406 22.527 29.22 21.795zM34.719 21.273h1.078c-.05-.731-.379-1.373-.893-1.879l-1.048.443C34.328 20.189 34.635 20.684 34.719 21.273z"
                            ></path>
                          </g>
                          <g>
                            <path
                              fill="#3f51b5"
                              d="M25.609 26.108l-1.333.563c-.629 1.476-1.85 2.36-3.519 2.36-.528 0-1.001-.103-1.437-.267l-1.258.531c.752.459 1.648.728 2.695.728C23.3 30.023 25.019 28.541 25.609 26.108zM28.828 26.859H27.75c.026.368.127.705.264 1.021l.989-.418C28.919 27.273 28.853 27.074 28.828 26.859zM32.695 23.641l-.779-.196-1.6.676c.234.081.487.156.762.224l1.289.328c.714.176 1.257.399 1.659.669l1.205-.509C34.703 24.318 33.878 23.934 32.695 23.641z"
                            ></path>
                          </g>
                          <g>
                            <path
                              fill="#03a9f4"
                              d="M29.003 27.463l-.989.418c.377.87 1.139 1.531 2.166 1.873l1.692-.714C30.493 29.007 29.415 28.396 29.003 27.463zM35.914 27.333c.035-.193.063-.39.063-.598 0-.784-.234-1.404-.745-1.902l-1.205.509c.579.39.856.883.856 1.51 0 .393-.131.75-.348 1.063L35.914 27.333z"
                            ></path>
                          </g>
                          <path
                            fill="#009688"
                            d="M35.914,27.333l-1.379,0.583c-0.472,0.682-1.394,1.132-2.55,1.132c-0.039,0-0.074-0.006-0.112-0.007l-1.692,0.714c0.514,0.171,1.086,0.269,1.71,0.269C34.098,30.023,35.615,28.964,35.914,27.333z"
                          ></path>
                        </svg>
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 48 48"
                          width="48px"
                          height="48px"
                        >
                          <path
                            fill="#7cb342"
                            d="M12 29c0 1.1-.9 2-2 2s-2-.9-2-2v-9c0-1.1.9-2 2-2s2 .9 2 2V29zM40 29c0 1.1-.9 2-2 2s-2-.9-2-2v-9c0-1.1.9-2 2-2s2 .9 2 2V29zM22 40c0 1.1-.9 2-2 2s-2-.9-2-2v-9c0-1.1.9-2 2-2s2 .9 2 2V40zM30 40c0 1.1-.9 2-2 2s-2-.9-2-2v-9c0-1.1.9-2 2-2s2 .9 2 2V40z"
                          ></path>
                          <path
                            fill="#7cb342"
                            d="M14 18v15c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V18H14zM24 8c-6 0-9.7 3.6-10 8h20C33.7 11.6 30 8 24 8zM20 13.6c-.6 0-1-.4-1-1 0-.6.4-1 1-1s1 .4 1 1C21 13.1 20.6 13.6 20 13.6zM28 13.6c-.6 0-1-.4-1-1 0-.6.4-1 1-1s1 .4 1 1C29 13.1 28.6 13.6 28 13.6z"
                          ></path>
                          <path
                            fill="#7cb342"
                            d="M28.3 10.5c-.2 0-.4-.1-.6-.2-.5-.3-.6-.9-.3-1.4l1.7-2.5c.3-.5.9-.6 1.4-.3.5.3.6.9.3 1.4l-1.7 2.5C29 10.3 28.7 10.5 28.3 10.5zM19.3 10.1c-.3 0-.7-.2-.8-.5l-1.3-2.1c-.3-.5-.2-1.1.3-1.4.5-.3 1.1-.2 1.4.3l1.3 2.1c.3.5.2 1.1-.3 1.4C19.7 10 19.5 10.1 19.3 10.1z"
                          ></path>
                        </svg>
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 48 48"
                          width="48px"
                          height="48px"
                        >
                          <polygon
                            fill="#40c4ff"
                            points="26,4 6,24 12,30 38,4"
                          ></polygon>
                          <polygon
                            fill="#40c4ff"
                            points="38,22 27,33 21,27 26,22"
                          ></polygon>
                          <rect
                            width="8.485"
                            height="8.485"
                            x="16.757"
                            y="28.757"
                            fill="#03a9f4"
                            transform="rotate(-45.001 21 33)"
                          ></rect>
                          <polygon
                            fill="#01579b"
                            points="38,44 26,44 21,39 27,33"
                          ></polygon>
                          <polygon
                            fill="#084994"
                            points="21,39 30,36 27,33"
                          ></polygon>
                        </svg>
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 48 48"
                          width="48px"
                          height="48px"
                        >
                          <path
                            fill="#039be5"
                            d="M32.876,5H15.124c-1.136,0-2.253,0.651-2.816,1.65L3.422,22.34c-0.563,1.01-0.563,2.31,0,3.32l8.886,15.69c0.563,1,1.68,1.65,2.816,1.65h17.752c1.136,0,2.253-0.651,2.816-1.65l8.886-15.69c0.563-1.01,0.563-2.31,0-3.32L35.692,6.65C35.129,5.651,34.012,5,32.876,5L32.876,5z"
                          ></path>
                          <path
                            fill="#fff"
                            d="M32.612,34h-3.051c-0.143,0-0.285-0.089-0.352-0.217l-5.161-9.585c-0.029-0.05-0.038-0.109-0.048-0.158c-0.01,0.049-0.019,0.109-0.048,0.158l-5.18,9.585c-0.057,0.128-0.19,0.207-0.333,0.217h-3.051c-0.276,0-0.485-0.366-0.342-0.623L20.093,24l-5.047-9.397c-0.124-0.227,0.028-0.553,0.266-0.603h3.127c0.133,0,0.266,0.089,0.333,0.208l5.18,9.585c0.028,0.049,0.038,0.099,0.048,0.158c0.01-0.059,0.019-0.109,0.048-0.158l5.161-9.585C29.285,14.079,29.418,14,29.561,14h3.051c0.266,0,0.466,0.356,0.342,0.603L27.907,24l5.047,9.377C33.097,33.634,32.887,34,32.612,34z"
                          ></path>
                        </svg>
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 48 48"
                          width="48px"
                          height="48px"
                        >
                          <path
                            fill="#80deea"
                            d="M24,34C11.1,34,1,29.6,1,24c0-5.6,10.1-10,23-10c12.9,0,23,4.4,23,10C47,29.6,36.9,34,24,34z M24,16 c-12.6,0-21,4.1-21,8c0,3.9,8.4,8,21,8s21-4.1,21-8C45,20.1,36.6,16,24,16z"
                          ></path>
                          <path
                            fill="#80deea"
                            d="M15.1,44.6c-1,0-1.8-0.2-2.6-0.7C7.6,41.1,8.9,30.2,15.3,19l0,0c3-5.2,6.7-9.6,10.3-12.4c3.9-3,7.4-3.9,9.8-2.5 c2.5,1.4,3.4,4.9,2.8,9.8c-0.6,4.6-2.6,10-5.6,15.2c-3,5.2-6.7,9.6-10.3,12.4C19.7,43.5,17.2,44.6,15.1,44.6z M32.9,5.4 c-1.6,0-3.7,0.9-6,2.7c-3.4,2.7-6.9,6.9-9.8,11.9l0,0c-6.3,10.9-6.9,20.3-3.6,22.2c1.7,1,4.5,0.1,7.6-2.3c3.4-2.7,6.9-6.9,9.8-11.9 c2.9-5,4.8-10.1,5.4-14.4c0.5-4-0.1-6.8-1.8-7.8C34,5.6,33.5,5.4,32.9,5.4z"
                          ></path>
                          <path
                            fill="#80deea"
                            d="M33,44.6c-5,0-12.2-6.1-17.6-15.6C8.9,17.8,7.6,6.9,12.5,4.1l0,0C17.4,1.3,26.2,7.8,32.7,19 c3,5.2,5,10.6,5.6,15.2c0.7,4.9-0.3,8.3-2.8,9.8C34.7,44.4,33.9,44.6,33,44.6z M13.5,5.8c-3.3,1.9-2.7,11.3,3.6,22.2 c6.3,10.9,14.1,16.1,17.4,14.2c1.7-1,2.3-3.8,1.8-7.8c-0.6-4.3-2.5-9.4-5.4-14.4C24.6,9.1,16.8,3.9,13.5,5.8L13.5,5.8z"
                          ></path>
                          <circle cx="24" cy="24" r="4" fill="#80deea"></circle>
                        </svg>
                      </li>
                    </ul>
                  </div>
                  <div className="list-item row">
                    <div className="col-lg-5 col-md-6 col-sm-12">
                      <ul className="list-item1">
                        <li>
                          <i className="bx bx-chevrons-right"></i>Native iOS App
                          Development
                        </li>
                        <li>
                          <i className="bx bx-chevrons-right"></i>Native Android
                          App Development
                        </li>
                        <li>
                          <i className="bx bx-chevrons-right"></i>PSD to HTML &
                          CSS
                        </li>
                        <li>
                          <i className="bx bx-chevrons-right"></i>CSS web
                          designing
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-12">
                      <ul className="list-item2">
                        <li>
                          <i className="bx bx-chevrons-right"></i>AngularJS,
                          NodeJS, ReactJS, VueJS
                        </li>
                        <li>
                          <i className="bx bx-chevrons-right"></i>JavaScript web
                          application development
                        </li>
                        <li>
                          <i className="bx bx-chevrons-right"></i>Re-design
                          existing websites & apps
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-5 col-md-12 col-sm-12"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div className="web-pro-left-text">
                    <img src={CrossPlatform} alt="" />
                  </div>
                </div>
              </div>
            </section>
            <section className="section1">
              <div className="row">
                <div
                  className="col-lg-5 col-md-12 col-sm-12"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div className="web-pro-left-text">
                    <img src={Ui_Ux} alt="" />
                  </div>
                </div>
                <div className="col-lg-7 col-md-12 col-sm-12 elementor-widget-wrap">
                  <div className="sub-title">
                    <h1>UI/UX Design</h1>
                  </div>
                  <div className="content">
                    <p>
                      Good designs play an undeniable role in digital success.
                      While being pleasant, our designs are appealing and
                      created keeping the end customer in mind. This makes them
                      ideal for the requirements of modern enterprises and
                      businesses. Our team of designers is thorough with the use
                      of the latest technologies and tools and our approach to
                      UI/UX design is innovative. Our responsive designs feature
                      uniform cross-platform compatibility.
                    </p>
                  </div>
                  <div className="icon-list">
                    <ul>
                      <li>
                        <img src={Figma} alt="" />
                      </li>
                      <li>
                        <img src={Photoshop} alt="" />
                      </li>
                      <li>
                        <img src={Illustrator} alt="" />
                      </li>
                      <li>
                        <img src={Blender} alt="" />
                      </li>
                    </ul>
                  </div>
                  <div className="list-item row">
                    <div className="col-lg-5 col-md-6 col-sm-12">
                      <ul className="list-item1">
                        <li>
                          <i className="bx bx-chevrons-right"></i>Mobile app UX
                          and UI design services
                        </li>
                        <li>
                          <i className="bx bx-chevrons-right"></i>Cross-platform
                          experiences design
                        </li>
                        <li>
                          <i className="bx bx-chevrons-right"></i>Web design
                          services
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="section1">
              <div className="row flex_direction">
                <div className="col-lg-7 col-md-12 col-sm-12 elementor-widget-wrap">
                  <div className="sub-title">
                    <h1>Quality Assurance (QA) & Project Management</h1>
                  </div>
                  <div className="content">
                    <p>
                      With the Quality Assurance services that our experts
                      deliver for Project Management, businesses are safeguarded
                      against ongoing and high losses as the potential risks
                      minimize and the odds of committing mistakes reduce
                      significantly. Right from the start of project plans,
                      project managers initiate the planning for their
                      deliverables’ quality. This highlights the areas where
                      efficiency can be improved to accentuate a brand’s
                      reputation and mitigate QA issues.
                    </p>
                  </div>
                  {/* <div className="icon-list">
                    <ul>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div> */}
                  <div className="list-item row">
                    <div className="col-lg-5 col-md-6 col-sm-12">
                      <ul className="list-item1">
                        <li>
                          <i className="bx bx-chevrons-right"></i>Manual QA
                          Testing
                        </li>
                        <li>
                          <i className="bx bx-chevrons-right"></i>Performance
                          Testing
                        </li>
                        <li>
                          <i className="bx bx-chevrons-right"></i>Statistical
                          process control
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-12">
                      <ul className="list-item2">
                        <li>
                          <i className="bx bx-chevrons-right"></i>
                          Failure testing
                        </li>
                        <li>
                          <i className="bx bx-chevrons-right"></i>Total quality
                          management
                        </li>
                        <li>
                          <i className="bx bx-chevrons-right"></i>Expert QA
                          Consultation
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-5 col-md-12 col-sm-12"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div className="web-pro-left-text">
                    <img
                      src={p1}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="section1">
              <div className="row">
                <div
                  className="col-lg-5 col-md-12 col-sm-12"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div className="web-pro-left-text">
                    <img src={Marketing} alt="" />
                  </div>
                </div>
                <div className="col-lg-7 col-md-12 col-sm-12 elementor-widget-wrap">
                  <div className="sub-title">
                    <h1>Marketing Automation</h1>
                  </div>
                  <div className="content">
                    <p>
                      Marketing automation refers to software platforms and
                      technologies designed for marketing departments and
                      organizations to more effectively market on multiple
                      channels online and automate repetitive tasks.
                    </p>
                  </div>
                  {/* <div className="icon-list">
                    <ul>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div> */}
                  <div className="list-item row">
                    <div className="col-lg-5 col-md-6 col-sm-12">
                      <ul className="list-item1">
                        <li>
                          <i className="bx bx-chevrons-right"></i>ActiveCampaign
                        </li>
                        <li>
                          <i className="bx bx-chevrons-right"></i>
                          Drip
                        </li>
                        <li>
                          <i className="bx bx-chevrons-right"></i>Zapier
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Specialties />
          <Footer />
          <Back_button />
        </>
      )}
    </>
  );
};

export default Services;
