import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { db, storage } from "../firebase";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import {
  collection,
  addDoc,
  updateDoc,
  serverTimestamp,
  doc,
  arrayUnion,
} from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Mymodal.css";

const Modals = ({ show, setShow }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_no: "",
    birth_date: "",
    address: "",
    city: "",
    state: "",
    postal_code: "",
    Applied: "",
  });
  const [images, setImages] = useState([]);

  const handleImageChange = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i];
      newImage["id"] = Math.random();
      setImages((prevState) => [...prevState, newImage]);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePublish = async () => {
    if (
      !formData.name ||
      !formData.email ||
      !formData.phone_no ||
      !formData.Applied ||
      !formData.birth_date ||
      !formData.address ||
      !formData.city ||
      !formData.state ||
      !formData.postal_code
    ) {
      toast("Please fill all the fields!!!", { type: "error" });
      return;
    }

    const docRef = await addDoc(collection(db, "Career"), {
      timestamp: serverTimestamp(),
      name: formData.name,
      email: formData.email,
      phone_no: formData.phone_no,
      birth_date: formData.birth_date,
      address: formData.address,
      city: formData.city,
      state: formData.state,
      postal_code: formData.postal_code,
      Applied: formData.Applied,
    });

    const downloadURLs = [];

    await Promise.all(
      images.map((image) => {
        const imageRef = ref(storage, `Career/${docRef.id}/${image.name}`);
        uploadBytes(imageRef, image, "data_url").then(async () => {
          const downloadURL = await getDownloadURL(imageRef);
          downloadURLs.push(downloadURL);
          await updateDoc(doc(db, "Career", docRef.id), {
            Resume: arrayUnion(downloadURL),
          });
        });
      })
    )
      .then(() => {
        toast("Resume added successfully", { type: "success" });
      })
      .catch((error) => {
        toast.error("Error adding Resume");
      });
    setFormData("");
    setTimeout(function () {
      window.location.reload(true);
    }, 5000);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <ToastContainer />
      <div className="career-modal">
        <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="container">
              <h2 className="modal-header-career">
                <span className="sp-main">Career Inquiries</span>
              </h2>
              <div className="modal-header-detail">
                <p>
                  Like to join the team? Drop in your details along with your CV
                  to discuss with our expert.
                </p>{" "}
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label for="full_name">Full Name*</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Your Name"
                      required=""
                      value={formData.name}
                      onChange={(e) => handleChange(e)}
                      autofocus="false"
                    />
                  </div>
                </div>

                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label for="email">Email Address*</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Your Email"
                      required=""
                      value={formData.email}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label for="phone">Phone No.*</label>
                    <input
                      type="tel"
                      id="phone"
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      name="phone_no"
                      className="form-control"
                      placeholder="Your Phone No."
                      required=""
                      value={formData.phone_no}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>

                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label for="birth_date">Date of Birth*</label>
                    <input
                      type="date"
                      name="birth_date"
                      className="form-control"
                      value={formData.birth_date}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label for="address">Address*</label>
                <input
                  type="text"
                  name="address"
                  className="form-control"
                  placeholder="Your Address"
                  required=""
                  value={formData.address}
                  onChange={(e) => handleChange(e)}
                />
              </div>

              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <div className="form-group">
                    <label for="city">City*</label>
                    <input
                      type="text"
                      name="city"
                      className="form-control"
                      placeholder="Enter City"
                      required=""
                      value={formData.city}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="form-group">
                    <label for="email">State*</label>
                    <input
                      type="text"
                      name="state"
                      className="form-control"
                      placeholder="Your State"
                      value={formData.state}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="form-group">
                    <label for="zip">Postal Code*</label>
                    <input
                      type="text"
                      name="postal_code"
                      className="form-control"
                      placeholder="Your Postal Code"
                      required=""
                      value={formData.postal_code}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label for="applied_for">Applied for*</label>
                    <select
                      className="form-control"
                      name="Applied"
                      required=""
                      placeholder="Select a Post"
                      value={formData.Applied}
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="Unity 3D">Unity 3D</option>
                      <option value="React Native">React Native</option>
                      <option value="Android">Android</option>
                      <option value="UI / UX">UI / UX</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label for="applied_for">Resume*</label>
                    <input
                      type="file"
                      name="resume"
                      className="form-control"
                      placeholder="Your Resume"
                      required=""
                      accept="image/png, image/jpeg, application/pdf, image/jpg"
                      onChange={(e) => handleImageChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn btn-block" onClick={handlePublish}>
              <i className="bx bx-right-arrow-circle" type="solid"></i>
              <span>Send Now</span>
            </button>
          </Modal.Footer>{" "}
        </Modal>
      </div>
    </>
  );
};

export default Modals;
