import React, { useState } from "react";
import Contactmodal from "./Contactmodal";
import Skypemodal from "./Skypemodal";
import "./Talk.css";

const Talk = () => {
  const [show, setShow] = useState(false);
  const [Sshow, setSShow] = useState(false);

  return (
    <>
      <Contactmodal show={show} setShow={setShow} />
      <Skypemodal show={Sshow} setShow={setSShow} />
      <section id="letstalk">
        <div className="mt-4">
          <div className="container">
            <div className="elementor-title">
              <h2>Let's Talk About Your Requirement</h2>
            </div>
            <div
              className="justify-content-center row"
              style={{ margin: "0 0 30px 0" }}
            >
              <div
                className="col-lg-3 col-md-6 col-sm-12"
                onClick={() => setShow(true)}
                style={{ cursor: "pointer" }}
              >
                <div className=" resize-imgs w-auto">
                  <div className="center-imgs">
                    <div className="icon-box ">
                      <div className="icon-main i1">
                        <i className="bx bxs-message-rounded"></i>
                      </div>
                      <div className="content-box">
                        <h5 className="box-title">
                          <a onClick={() => setShow(true)}>We Will Call You</a>
                        </h5>
                        <p>Your details please!!</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 col-sm-12"
                onClick={() => setSShow(true)}
                style={{ cursor: "pointer" }}
              >
                <div className=" resize-imgs w-auto">
                  <div className="center-imgs">
                    <div className="icon-box">
                      <div className="icon-main i2">
                        <i className="bx bxl-skype"></i>
                      </div>
                      <div className="content-box">
                        <h5 className="box-title">
                          <a onClick={() => setSShow(true)}>Skype Us</a>
                        </h5>
                        <p>Your details please!!</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <a href="mailto:admin@softholic.in">
                  <div className=" resize-imgs w-auto">
                    <div className="center-imgs">
                      <div className="icon-box">
                        <div className="icon-main i3">
                          <i className="bx bxl-google"></i>
                        </div>
                        <div className="content-box">
                          <h5 className="box-title">
                            <a href="/" style={{ pointerEvents: "none" }}>
                              Email Us
                            </a>
                          </h5>
                          <p>
                            <a href="mailto:admin@softholic.in">
                              admin@softholic.in
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <a href="tel:+919726981087 ">
                  <div className=" resize-imgs w-auto">
                    <div className="center-imgs">
                      <div className="icon-box ">
                        <div className="icon-main i4">
                          <i className="bx bxs-phone"></i>
                        </div>
                        <div className="content-box">
                          <h5 className="box-title">
                            <a href="/" style={{ pointerEvents: "none" }}>
                              Let's Talk
                            </a>
                          </h5>
                          <p>
                            <a
                              href="tel:+919726981087 "
                              style={{ color: "#fff" }}
                            >
                              +91 97269 81087
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Talk;
