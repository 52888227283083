import React, { useEffect, useState } from "react";
import "./Portfolio.css";
import Footer from "../Footer/Footer";
import Back_button from "../Back-to-top/back_button";
import Navbar from "../Navbar/Navbar";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import bubble_1 from "../img/bubble-1.png";
import bubble_2 from "../img/bubble-2.png";
import circle from "../img/circle-2.png";
// import Ui_ux from "./Ui_ux";
// import Web from "./Web";
// import App from "./App";
import p1 from "../img/PortfolioUi/Furniture.jpg";
import p2 from "../img/PortfolioUi/Pdf.jpg";
import Subbanner from "../Subbanner/Subbanner";

const Portfolio = () => {
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    getloading();
  }, []);

  const getloading = () => {
    // setLoader(false);
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };
  return (
    <>
      <Navbar />
      {loader ? (
        <div id="preloader"></div>
      ) : (
        <>
          <Subbanner pagename={"Portfolio"} />
          <section class="our-work">
            <div class="container">
              <h2
                class="section-title3 wow fadeInUp"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                Our Most Successful Projects at A Glance
              </h2>
              <div class="row work-sec">
                <div class="work-sec-bg"></div>
                <div class="work-content-sec d-lg-flex">
                  <div class="col-lg-6 col-12 work-img p-0">
                    <img src={p1} widht="704px" height="100%" alt="unikwork" />
                  </div>
                  <div class="col-lg-6 col-12 work-main">
                    <div class="work-content">
                      <a href="javascript:void(0)" class="d-block">
                        <h3 class="mb-4">
                          Lojong <span> - Meditation guide</span>
                        </h3>
                      </a>
                      <p>
                        Lojong application has hundreds of videos that can guide
                        you to improve your daily life and reduce anxiety. It
                        helps users with mindfulness and reduces stress. we have
                        been approached to create a mobile application for them.
                        We stepped in to create a roadmap for their product and
                        worked closely with the product owner and design team.
                        We worked with them to launch their application to
                        market in 2016
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row work-sec carblip-work mb-5">
                <div class="work-sec-bg carblip-bg"></div>
                <div class="work-content-sec carblip-sec d-lg-flex">
                  <div class="col-lg-6 col-12 work-main">
                    <div class="work-content">
                      <a href="" class="d-block">
                        <h3 class="mb-4">
                          CarBlip <span> - Buy or Lease a car</span>
                        </h3>
                      </a>
                      <p>
                        Carblip helps to eliminate contacting a dealer for
                        buying your dream car. It allows a user to select a car
                        brand and configure it. After selecting the car user can
                        submit the document and also user can trade in the
                        existing car. Carblip and Unikwork worked together to
                        develop mobile and web application which gives the best
                        user experience.
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-6 col-12 work-img p-0">
                    <img src={p2} widht="704px" height="100%" alt="unikwork" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
          <Back_button />
        </>
      )}
    </>
  );
};

export default Portfolio;
