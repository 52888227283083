import React from "react";
import "./Navbar.css";
import logo from "../img/Softholic.png";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const Navigate = useNavigate();
  // React.useEffect(() => {
  //   const menuLis = document.querySelectorAll("#activeid > li > a");

  //   for (let li of menuLis) {
  //     li.addEventListener("click", function () {
  //       // 1. Remove Class from All Lis
  //       for (let li of menuLis) {
  //         li.classList.remove("active");
  //       }

  //       // 2. Add Class to Relevant Li
  //       this.classList.add("active");
  //     });
  //   }
  // }, []);

  // const NavigationActive = (event) => {
  //   event.target.classList.add("active");
  //   // event.target.classList.remove('active')
  // };

  const select = (el, all = false) => {
    el = el.trim();
    if (all) {
      return [...document.querySelectorAll(el)];
    } else {
      return document.querySelector(el);
    }
  };

  const navbarOnclick = () => {
    select("#navbar").classList.toggle("navbar-mobile");
    select("#x-nav").classList.toggle("bi-x");
    // this.classList.toggle('bi-list')
    // this.classList.toggle('bi-x')
    // setclose(!close);
  };

  React.useEffect(() => {
    const navbar = document.querySelector(".header-transparent");
    window.onscroll = () => {
      if (window.scrollY > 100) {
        navbar?.classList?.add("header-scrolled");
      } else {
        navbar?.classList?.remove("header-scrolled");
      }
    };
  }, []);

  const Career = () => {
    Navigate("/Career");
  };
  const Portfolio = () => {
    Navigate("/Portfolio");
  };
  const Home = () => {
    Navigate("/");
  };
  const About = () => {
    Navigate("/About");
  };
  const Services = () => {
    Navigate("/Services");
  };
  const Contact = () => {
    Navigate("/Contact");
  };

  return (
    <>
      <div>
        <header
          id="header"
          className="fixed-top d-flex align-items-center header-transparent"
        >
          <div className="container d-flex align-items-center">
            <h1 className="logo me-auto" onClick={Home}>
              <img src={logo} alt="" className="img-fluid" />
              <a href="/">SoftHolic</a>
            </h1>

            <nav id="navbar" className="navbar order-last order-lg-0">
              <ul id="activeid">
                <li>
                  <a className="nav-link scrollto  " href="/">
                    Home
                  </a>
                </li>
                <li onClick={About}>
                  <a className="nav-link scrollto" href={About}>
                    About
                  </a>
                </li>
                <li onClick={Services}>
                  <a className="nav-link scrollto" href={Services}>
                    Services
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto " href="/#Hportfolio">
                    Portfolio
                  </a>
                </li>
                <li onClick={Career}>
                  <a className="nav-link scrollto" href={Career}>
                    Career
                  </a>
                </li>
                <li onClick={Contact}>
                  <a className="nav-link scrollto" href={Contact}>
                    Contact
                  </a>
                </li>
              </ul>
              <i
                className="bi bi-list mobile-nav-toggle"
                onClick={navbarOnclick}
                id="x-nav"
                /*  className={
                close
                  ? "bi mobile-nav-toggle bi-list"
                  : "bi mobile-nav-toggle bi-x"
              } */
              ></i>
            </nav>

            <div className="social-links">
              {/* <a href="/" className="twitter">
                <i className="bi bi-twitter"></i>
              </a>
              <a
                href="https://www.facebook.com/softholic.india"
                className="facebook"
              >
                <i className="bi bi-facebook"></i>
              </a> */}
              <a
                href="https://www.linkedin.com/company/softholic-india/"
                className="linkedin"
              >
                <i className="bi bi-linkedin"></i>
              </a>
              <a
                href="https://www.instagram.com/softholic.india/?hl=en"
                className="instagram"
              >
                <i className="bi bi-instagram"></i>
              </a>
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

export default Navbar;
