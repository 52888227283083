import React from "react";
import "./Specialties.css";
import html from "../img/Specialties/html.svg";
import angular from "../img/Specialties/angular.svg";
import react from "../img/Specialties/atom (1).png";
import typescript from "../img/Specialties/typescript.svg";

const Frontend = () => {
  return (
    <div className="mt-4" data-aos="fade-up">
      <div className="justify-content-center row" style={{ margin: 0 }}>
        <div className="mt-3 resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={html}
                alt="Photoshop"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              HTML 5
            </h4>
          </div>
        </div>
        <div className="mt-3 resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={react}
                alt="Figma"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              React js
            </h4>
          </div>
        </div>
        <div className="mt-3 resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={typescript}
                alt="Illustrator"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Typescript
            </h4>
          </div>
        </div>
        <div className="mt-3 resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={angular}
                alt="Maya"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Angular
            </h4>
          </div>
        </div>
       
      </div>
    </div>
  );
};

export default Frontend;
