import React from "react";
import "./gallery.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import GLightbox from "glightbox";
import p1 from "../img/Gallery/Bank.png";
import p2 from "../img/Gallery/Battle_Game.png";
import p3 from "../img/Gallery/Doctor_app.png";
import p4 from "../img/Gallery/Furniture.png";
import p5 from "../img/Gallery/Ludo_game.png";
import p6 from "../img/Gallery/Meow_app.png";
import p7 from "../img/Gallery/Music.png";
import p8 from "../img/Gallery/Ocean_game.png";
import p9 from "../img/Gallery/Pdf_app.png";
import p10 from "../img/Gallery/QR Code.png";
import p11 from "../img/Gallery/Shoes_app.png";
import p12 from "../img/Gallery/Sushi_App.png";
import p13 from "../img/Gallery/Wather.png";
import p14 from "../img/Gallery/Word_game.png";

const Gallery = () => {
  React.useEffect(() => {
    const galleryLightbox = GLightbox({
      selector: ".gallery-lightbox",
    });
  }, []);

  const select = (el, all = false) => {
    el = el.trim();
    if (all) {
      return [...document.querySelectorAll(el)];
    } else {
      return document.querySelector(el);
    }
  };


  return (
    <section id="gallery" className="gallery section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h3>Gallery</h3>
          <p>Checkout Our App Interface Screenshots.</p>
        </div>
      </div>

      <div className="container-fluid" data-aos="fade-up">
        <div className="gallery-slider swiper">
          <Swiper
            modules={[Pagination, Navigation]}
            pagination={{
              el: ".swiper-pagination",
              type: "bullets",
              clickable: "true",
            }}
            spaceBetween={30}
            slideToClickedSlide={true}
            slidesPerView={"auto"}
            centeredSlides={true}
            loop={true}
            speed={400}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              992: {
                slidesPerView: 5,
                spaceBetween: 30,
              },
              1200: {
                slidesPerView: 7,
                spaceBetween: 30,
              },
            }}
          >
            <SwiperSlide>
              {" "}
              <a
                href={p1}
                className="gallery-lightbox"
                data-gall="gallery-carousel"
              >
                <img src={p1} alt="" className="img-fluid" />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <a
                href={p2}
                className="gallery-lightbox"
                data-gall="gallery-carousel"
              >
                <img src={p2} alt="" className="img-fluid" />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <a
                href={p3}
                className="gallery-lightbox"
                data-gall="gallery-carousel"
              >
                <img src={p3} alt="" className="img-fluid" />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <a
                href={p4}
                className="gallery-lightbox"
                data-gall="gallery-carousel"
              >
                <img src={p4} alt="" className="img-fluid" />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <a
                href={p5}
                className="gallery-lightbox"
                data-gall="gallery-carousel"
              >
                <img src={p5} alt="" className="img-fluid" />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <a
                href={p6}
                className="gallery-lightbox"
                data-gall="gallery-carousel"
              >
                <img src={p6} alt="" className="img-fluid" />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <a
                href={p7}
                className="gallery-lightbox"
                data-gall="gallery-carousel"
              >
                <img src={p7} alt="" className="img-fluid" />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <a
                href={p8}
                className="gallery-lightbox"
                data-gall="gallery-carousel"
              >
                <img src={p8} alt="" className="img-fluid" />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <a
                href={p9}
                className="gallery-lightbox"
                data-gall="gallery-carousel"
              >
                <img src={p9} alt="" className="img-fluid" />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <a
                href={p10}
                className="gallery-lightbox"
                data-gall="gallery-carousel"
              >
                <img src={p10} alt="" className="img-fluid" />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <a
                href={p11}
                className="gallery-lightbox"
                data-gall="gallery-carousel"
              >
                <img src={p11} alt="" className="img-fluid" />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <a
                href={p12}
                className="gallery-lightbox"
                data-gall="gallery-carousel"
              >
                <img src={p12} alt="" className="img-fluid" />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <a
                href={p13}
                className="gallery-lightbox"
                data-gall="gallery-carousel"
              >
                <img src={p13} alt="" className="img-fluid" />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <a
                href={p14}
                className="gallery-lightbox"
                data-gall="gallery-carousel"
              >
                <img src={p14} alt="" className="img-fluid" />
              </a>
            </SwiperSlide>
          </Swiper>

          <div className="swiper-pagination"></div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
