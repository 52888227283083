import React from 'react'
import "./Specialties.css";
import Wordpress from "../img/Specialties/Wordpress.svg";
import Joomla from "../img/Specialties/Joomla.svg";
import Magento from "../img/Specialties/Magento.svg";
import Shopify from "../img/Specialties/Shopify.svg";

const CMS = () => {
  return (
    <div className="mt-4" data-aos="fade-up">
      <div className="justify-content-center row" style={{ margin: 0 }}>
        <div className="mt-3 resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={Wordpress}
                alt="Photoshop"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Wordpress
            </h4>
          </div>
        </div>
        <div className="mt-3 resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={Joomla}
                alt="Figma"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Joomla
            </h4>
          </div>
        </div>
        <div className="mt-3 resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={Magento}
                alt="Illustrator"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Magento
            </h4>
          </div>
        </div>
        <div className="mt-3 resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={Shopify}
                alt="Maya"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Shopify
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CMS